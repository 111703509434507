import React, { Component } from "react";
import { graphql, Link } from "gatsby";

import { Layout, Loader, Pagination } from "components";

const PostsPageTemplate = ({ data, location, pageContext }) => {
  const { page, posts, categories } = data;
  const { currentPageNumber, numberOfPages, nextPagePath, previousPagePath } = pageContext;

  return (
    <Layout pageTitle={page.title || "Blog"}>
      {page.acfLayout?.layout?.length > 0 ? (
        <Loader
          data={page.acfLayout.layout.map(({ ...layout }) => ({
            ...layout,
            currentPageNumber,
            numberOfPages,
            nextPagePath,
            previousPagePath,
            posts: posts.nodes,
            categories: categories,
          }))}
          location={location}
        />
      ) : (
        page.content && (
          <div className="layout">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        )
      )}
    </Layout>
  );
};

export default PostsPageTemplate;

export const query = graphql`
  query PostsPage($id: String!, $offset: Int!, $postsPerPage: Int!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      acfLayout {
        fieldGroupName
        layout {
          ...HeroQuery
          ...ImageAndContentBlockQuery
          ...TextContentBlockQuery
          ...ContainedImageBlockQuery
          ...CardBlockQuery
          ...StaffProfilesQuery
          ...TabbedContentBlockQuery
          ...FormBlockQuery
          ...BlogPostListQuery
        }
      }
    }
    categories: allWpCategory(filter: { count: { gt: 0 } }) {
      edges {
        category: node {
          uri
          id
          name
          slug
          posts {
            nodes {
              id
              uri
              slug
            }
          }
        }
      }
    }
    posts: allWpPost(sort: { fields: [date], order: DESC }, limit: $postsPerPage, skip: $offset) {
      nodes {
        content
        id
        title
        excerpt
        uri
        slug
        date(formatString: "DD/MM/YYYY")
        featuredImage {
          node {
            title
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED, width: 880, height: 586)
              }
            }
          }
        }
        categories {
          nodes {
            id
            name
            slug
          }
        }
      }
    }
  }
`;
